import React, { useMemo, useState, useEffect } from "react";
import "./CollectedBills.css";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import Slider from "react-slick";
import { Button } from "../../../components/Button";
import { useTable } from "react-table";
import { CustomNextArrow, CustomPrevArrow } from "./CustomArrow";
import getCollectedBills from "../../../actions/creation/getCollectedBills";
import ImageViewer from "./imageCollectedBills";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked"; // Filled icon for selected button
import DeleteIcon from "@material-ui/icons/Delete"; // Import the Delete icon
import getAssignSku from "../../../actions/creation/getAssignSku";
import CustomDropdown from "./CollectedBillsDropdown";
import Add from "@material-ui/icons/Add";
import ImageIcon from "@material-ui/icons/Image";
import { DASHBOARDROUTES } from "../../../helpers/constants/routes";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core"; // Import Material-UI components from core
import { triggerSimpleAjax } from "../../../helpers/httpHelper";
import { DELETE_COLLECTED_BILLS_SKU } from "../../../helpers/constants/apiUrl";
import createSkuCollectedBills from "../../../actions/creation/createSKUBills";
import getAddNewSkuData from "../../../actions/creation/getNewSkuData";
// import newAddedSKu from './cardData';
import { curiousBlue300, white } from "../../../theme/colors";

let getcategoryDetailsStaticQueryParams = "&exclude_retailers=true";

const CollectedBills = (props) => {
  const [selectedCard, setSelectedCard] = useState(0);
  const [isExpanded, setIsExpanded] = useState(true);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [cardDataForSKus, setCardDataForSKus] = useState();
  const [tableData, setTableData] = useState([]);
  const [skuName, setSkuName] = useState([]);
  const [savedCard, setSavedCard] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [results, setResult] = useState([]);
  const [refreshData, setRefreshData] = useState(false); // State to trigger data refresh
  const [newAddedSKu, setNewAddedSKu] = useState([]); // State to trigger data refresh
  const [selectedSkuID, setSelectedSkuID] = useState(null);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [hasNewSku, setHasNewSku] = useState(false);



  useEffect(() => {
    async function fetchData() {
      try {
        const req = props.outletDetails.workflow_object;
        setLoading(true);
        const response = await getCollectedBills(req);
        setCardDataForSKus(response);
        setLoading(false);
      } catch (error) {
        setError(error.message || "Something went wrong");
        setLoading(false);
      }
    }
    fetchData();
  }, [refreshData]);





  //Required this for the New SKu Data

  useEffect(() => {
    if (selectedCard === "new-sku") {
      const req = props.outletDetails.workflow_object;
      async function fetchNewSKuData() {
        try {
          // const req = props.outletDetails.workflow_object;
          setLoading(true);
          const response = await getAddNewSkuData(req);
          setNewAddedSKu(response);
          setLoading(false);
        } catch (error) {
          setError(error.message || "Something went wrong");
          setLoading(false);
        }
      }
      fetchNewSKuData();
    }
  }, [selectedCard]);

  useEffect(() => {
    const subProjectSlugId = props.outletDetails.sub_project.slug;
    const req = `?sub_project__slug=${subProjectSlugId}${getcategoryDetailsStaticQueryParams}`;

    getAssignSku(req).then((response) => {
      const { results } = response;
      const skuNames = results.map((name) => name.sku_name);
      const skuData = results.map((item) => ({
        sku_name: item.sku_name,
        sku: item.sku,
      }));

      setResult(skuData);
      setSkuName(skuNames);
    });
  }, [props.outletDetails.sub_project.slug]);

  const handleCardClick = (index) => {
    setSelectedCard(index);
  };

  const toggleExpandCollapse = () => {
    setIsExpanded(!isExpanded);
  };


  const handleOpenDialog = (skuID, rowIndex) => {
    setSelectedSkuID(skuID);
    setSelectedRowIndex(rowIndex);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const confirmDelete = () => {
    handleDeleteRow(); // Call your existing delete function
    setOpenDialog(false); // Close the dialog
  };

  const checkForNewSkus = () => {
    const newSkuExists = tableData.some(row => 
      row.sku_name && 
      !skuName.includes(row.sku_name) // Adjust based on your condition
    );
    setHasNewSku(newSkuExists);
  };
  
  // Call this function whenever tableData changes
  useEffect(() => {
    checkForNewSkus();
  }, [tableData]);
  


  const handleSaveNext = async () => {
    setSavedCard(selectedCard); // Save the currently selected card
    const currentCardData = cardDataForSKus?.results[selectedCard];
    const combinedTableData = Object.assign({}, ...tableData);; // Assuming tableData is structured correctly
    console.log("combinedTableData",combinedTableData);
    let isOuterEdit = false;
    let isOuterSkuDataEdit = false;

    const getFieldName = (key) => {
        const fieldDetail = currentCardData.assigned_sku_template_field.find(
            (field) => field.id === parseInt(key)
        );
        return fieldDetail ? fieldDetail.name : key; // Fallback to key if name is not found
    };

    const getFieldType = (key) => {
        const fieldDetail = currentCardData.assigned_sku_template_field.find(
            (field) => field.id === parseInt(key)
        );
        return fieldDetail ? fieldDetail.type : key; // Fallback to key if type is not found
    };

    // const updatedSkuData = currentCardData.simplifiedinvoiceskudata?.map((skuItem) => {
    //     const tableSkuName = combinedTableData["sku_name"];
    //     let isSkuDataEdited = false;

    //     const updatedSkuDetails = skuItem.simplifiedinvoiceskudetails.map((skuDetail) => {
    //         const tableValue = combinedTableData[skuDetail.sku_template_field];

    //         // Always update single_input with tableValue, regardless of its previous state
    //         if (tableValue !== undefined && tableValue !== skuDetail.single_input) {
    //             isSkuDataEdited = true;
    //             isOuterEdit = true;
    //             return {
    //                 ...skuDetail,
    //                 is_edit: true,
    //                 single_input: tableValue, // Update single_input with new value
    //             };
    //         } 

    //         return skuDetail;
    //     });

    //     // Check if any assigned_sku_template_field is missing from skuItem and add it
    //     currentCardData.assigned_sku_template_field.forEach((field) => {
    //         const fieldExists = skuItem.simplifiedinvoiceskudetails.some((detail) => {
    //             return detail.sku_template_field === field.id;
    //         });

    //         if (!fieldExists) {
    //             let tableValue = ""; // Initialize with an empty string

    //             // Loop through combinedTableData array to find the value for the current field.id
    //             combinedTableData.forEach((dataItem) => {
    //                 if (dataItem.hasOwnProperty(field.id)) {
    //                     tableValue = dataItem[field.id]; // Assign the value if found
    //                 }
    //             });

    //             updatedSkuDetails.push({
    //                 id: null,
    //                 sku_template_field: field.id,
    //                 sku_template_field_type: field.type,
    //                 sku_template_field_name: field.name,
    //                 single_input: tableValue || "", // Use the found value or fallback to an empty string
    //                 simplifiedinvoiceskuimageanswer: [],
    //                 is_edit: true,
    //             });

    //             isSkuDataEdited = true; // Flag that SKU data has been edited
    //             isOuterEdit = true; // Mark outer edit as true
    //         }
    //     });

    //     if (isSkuDataEdited) {
    //         isOuterSkuDataEdit = true;
    //     }

    //     const matchedSku = results.find(
    //         (result) => result.sku_name === tableSkuName
    //     );

    //     const isSkuNameEdited =
    //         tableSkuName !== undefined && tableSkuName !== skuItem.sku_name;

    // console.log("updatedSkuDetails",updatedSkuDetails)


    //     return {
    //         ...skuItem,
    //         sku_name: isSkuNameEdited ? tableSkuName : skuItem.sku_name, // Update sku_name if it's edited
    //         sku: matchedSku ? matchedSku.sku : skuItem.sku, // Update sku if matched, otherwise retain existing
    //         simplifiedinvoiceskudetails: updatedSkuDetails,
    //         is_edit: isSkuNameEdited,
    //         is_edit_simplifiedinvoiceskudetails: isSkuDataEdited,
    //     };
    // });

    const updatedSkuData = currentCardData.simplifiedinvoiceskudata?.map((skuItem) => {
      // Find the matching row for this skuItem in the tableData
      const matchingRow = tableData.find(row => row.sku_name === skuItem.sku_name);
  
      let isSkuDataEdited = false;
  
      const updatedSkuDetails = skuItem.simplifiedinvoiceskudetails.map((skuDetail) => {
          // Get the table value for the current sku_template_field, but only from the matching row
          const tableValue = matchingRow ? matchingRow[skuDetail.sku_template_field] : undefined;
  
          // Update the single_input with the new value from the matching row, if available
          if (tableValue !== undefined && tableValue !== skuDetail.single_input) {
              isSkuDataEdited = true;
              isOuterEdit = true;
              return {
                  ...skuDetail,
                  is_edit: true,
                  single_input: tableValue, // Update single_input with new value
              };
          }
  
          return skuDetail;
      });
  
      // Check if any assigned_sku_template_field is missing from skuItem and add it
      currentCardData.assigned_sku_template_field.forEach((field) => {
          const fieldExists = skuItem.simplifiedinvoiceskudetails.some((detail) => {
              return detail.sku_template_field === field.id;
          });
  
          if (!fieldExists) {
              const tableValue = matchingRow ? matchingRow[field.id] : "";
  
              updatedSkuDetails.push({
                  id: null,
                  sku_template_field: field.id,
                  sku_template_field_type: field.type,
                  sku_template_field_name: field.name,
                  single_input: tableValue || "", // Use the found value or fallback to an empty string
                  simplifiedinvoiceskuimageanswer: [],
                  is_edit: true,
              });
  
              isSkuDataEdited = true;
              isOuterEdit = true;
          }
      });
  
      if (isSkuDataEdited) {
          isOuterSkuDataEdit = true;
      }
  
      const isSkuNameEdited =
          matchingRow && matchingRow.sku_name !== undefined && matchingRow.sku_name !== skuItem.sku_name;
  
      return {
          ...skuItem,
          sku_name: isSkuNameEdited ? matchingRow.sku_name : skuItem.sku_name, // Update sku_name if it's edited
          simplifiedinvoiceskudetails: updatedSkuDetails,
          is_edit: isSkuNameEdited || isSkuDataEdited,
          is_edit_simplifiedinvoiceskudetails: isSkuDataEdited,
      };
  });
  

    const newRows = tableData
        .filter((row) => row.isNew) // Assuming there's an isNew property for new rows
        .map((row) => {
            // Get SKU based on the sku_name from currentCardData
            const matchedSku = results.find(
                (result) => result.sku_name === row.sku_name
            );
            return {
                sku_name: row.sku_name || "", // Add sku_name if necessary
                sku: matchedSku ? matchedSku.sku : "",
                simplifiedinvoiceskudetails: Object.entries(row)
                    .filter(([key]) => key !== "id" && key !== "isNew") // Exclude identifier keys
                    .map(([key, value]) => ({
                        sku_template_field: key,
                        sku_template_field_name: getFieldName(key), // Extract sku_template_field_name from assigned_sku_template_field using helper function
                        sku_template_field_type: getFieldType(key),
                        single_input: value,
                        simplifiedinvoiceskuimageanswer: [],
                    })),
            };
        });

    // Prepare payload
    const payload = {
        ...currentCardData,
        simplifiedinvoiceskudata: updatedSkuData,
        new_simplifiedinvoiceskudata: newRows, // Create a new key for the new rows
        is_edit: isOuterEdit,
        is_edit_simplifiedinvoiceskudata: isOuterSkuDataEdit,
    };

    try {
        const response = await createSkuCollectedBills(
            currentCardData.id,
            payload
        );

        // Trigger data refresh by toggling the refreshData state
        setRefreshData((prev) => !prev);
    } catch (error) {
        console.error("Error sending payload:", error);
    }

    // Automatically move to the next card if available
    if (selectedCard < cardDataForSKus?.results.length - 1) {
        setSelectedCard(selectedCard + 1);
    } else {
        props.setIsSliderComplete(true); // Trigger the callback when the last button is reached
    }
};



const handleSaveNextold = async () => {
  setSavedCard(selectedCard); // Save the currently selected card
  const currentCardData = cardDataForSKus?.results[selectedCard];
  console.log("currentCardData",currentCardData.id)
  const combinedTableData = Object.assign({}, ...tableData);
  let isOuterEdit = false; 
  let isOuterSkuDataEdit = false;

  const getFieldName = (key) => {
    const fieldDetail = currentCardData.assigned_sku_template_field.find(
      field => field.id === parseInt(key)
    );
    return fieldDetail ? fieldDetail.name : key; // Fallback to key if name is not found
  };

  const getFieldType = (key) => {
    const fieldDetail = currentCardData.assigned_sku_template_field.find(
      field => field.id === parseInt(key)
    );
    return fieldDetail ? fieldDetail.type : key; // Fallback to key if type is not found
  };

 
  console.log("resultssssss",results);


  // Helper function to get the `sku` based on `sku_name`
  

  // Prepare updated SKU data
  const updatedSkuData = currentCardData.simplifiedinvoiceskudata?.map((skuItem) => {
    const tableSkuName = combinedTableData['sku_name']; // Extract sku_name from tableData (adjust key if necessary)

    let isSkuDataEdited = false;

    const updatedSkuDetails = skuItem.simplifiedinvoiceskudetails.map((skuDetail) => {
      const tableValue = combinedTableData[skuDetail.sku_template_field];

      if (
        tableValue !== undefined &&
        tableValue !== skuDetail.single_input
      ) {
        isSkuDataEdited = true; 
        isOuterEdit = true; 
        return {
          ...skuDetail,
          is_edit: true, 
          single_input: tableValue, 
        };
      }
      return skuDetail; 
    });

    if (isSkuDataEdited) {
      isOuterSkuDataEdit = true; 
    }
    const isSkuNameEdited = tableSkuName !== undefined && tableSkuName !== skuItem.sku_name;

    return {
      ...skuItem,
      simplifiedinvoiceskudetails: updatedSkuDetails,
      is_edit: isSkuNameEdited,
      is_edit_simplifiedinvoiceskudetails:isSkuDataEdited
    };
  });

  // Prepare new rows separately and structure them
  const newRows = tableData
  .filter(row => row.isNew) // Assuming there's an isNew property for new rows
  .map(row => {
    // Get SKU based on the sku_name from currentCardData
   
    const matchedSku = results.find(result => result.sku_name === row.sku_name);

    return {
      sku_name: row.sku_name || "", // Add sku_name if necessary
      sku: matchedSku ? matchedSku.sku : "",
      simplifiedinvoiceskudetails: Object.entries(row)
        .filter(([key]) => key !== 'id' && key !== 'isNew') // Exclude identifier keys
        .map(([key, value]) => ({
          sku_template_field: key,
          sku_template_field_name: getFieldName(key), // Extract sku_template_field_name from assigned_sku_template_field using helper function
          sku_template_field_type: getFieldType(key),
          single_input: value,
          simplifiedinvoiceskuimageanswer: []
        })),
    };
  });


  // Prepare payload
  const payload = {
    ...currentCardData,
    simplifiedinvoiceskudata: updatedSkuData,
    new_simplifiedinvoiceskudata: newRows, // Create a new key for the new rows
    is_edit: isOuterEdit,
    is_edit_simplifiedinvoiceskudata: isOuterSkuDataEdit,
  };

  try {
    const response = await createSkuCollectedBills(currentCardData.id, payload); // Pass id as parameter
    console.log("Payload response:", response);
  } catch (error) {
    console.error("Error sending payload:", error);
  }

  

  // Automatically move to the next card if available
  if (selectedCard < cardDataForSKus?.results.length - 1) {
    setSelectedCard(selectedCard + 1);
  }
};


  const columns = useMemo(() => {
    const firstSkuData =
      cardDataForSKus?.results[selectedCard]?.assigned_sku_template_field || [];
    const seenHeaders = new Set();
    return [
      {
        Header: "SKU Name", // Always have "SKU Name" as the first column
        accessor: "sku_name",
      },
      // Directly map over firstSkuData if it's an array
      ...(firstSkuData?.map((detail) => {
        if (!detail) return null; // Handle undefined detail
        // Using `name` (or other relevant field) as the unique header
        let uniqueHeader = detail.name || `Detail ${detail.id}`;
        let accessor = detail.id?.toString() || "undefined_id";

        // Handle duplicate accessor keys
        let index = 1;
        while (seenHeaders.has(accessor)) {
          accessor = `${uniqueHeader}_${index}`;
          index++;
        }
        seenHeaders.add(accessor);

        return {
          Header: uniqueHeader, // Display field name as header
          accessor: accessor, // Key for accessing this field's value
        };
      }) || []),
    ];
  }, [selectedCard, cardDataForSKus]);

  const data = useMemo(() => {
    const currentResults = cardDataForSKus?.results[selectedCard] || {};
    const currentData =
      currentResults.simplifiedinvoiceskudata?.map((skuData) => ({
        sku_name: skuData.sku_name,
        invoice_date: currentResults.invoice_date,
        ...skuData.simplifiedinvoiceskudetails.reduce(
          (acc, detail) => ({
            ...acc,
            [detail.sku_template_field.toString()]: detail.single_input,
          }),
          {}
        ),
      })) || [];
    const emptyRow = {};
    columns.forEach((column) => {
      emptyRow[column.accessor] = ""; // Set each key to an empty string
    });
    return currentData;
  }, [selectedCard, columns, cardDataForSKus]);

  const addEmptyRow = () => {
    const emptyRow = {
      id: Date.now(), // Unique identifier using timestamp or you can use a UUID library
      isNew: true, // Flag to indicate it's a new row
    };
    columns.forEach((column) => {
      emptyRow[column.accessor] = ""; // Set each key to an empty string
    });
    setTableData((prevData) => [...prevData, emptyRow]);
  };

  useEffect(() => {
    // Initialize the tableData when data changes
    setTableData(data);
  }, [data]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data: tableData,
    });

 

  const handleInputChange = (event, rowIndex, columnId) => {
    const newValue = event.target.value;
    
    
    setTableData((prevData) => {
      const updatedData = [...prevData];
  
      updatedData[rowIndex] = {
        ...updatedData[rowIndex],
        [columnId]: newValue,
      };
  
      console.log("Updated Table Data:", updatedData);
      
      return updatedData;
    });
  };
  


  const newAddedSKuTest = newAddedSKu?.results ?? [];
  console.log("newSKuData",newAddedSKuTest);

  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
  };

  const getInvoiceHeaders = (data, index) => {
    return (
      data?.results[index]?.simplifiedinvoiceheaderanswer.map((item) => ({
        header: item.invoice_header_name,
        singleInput: item.single_input,
      })) || []
    );
  };

  const invoiceHeaders = getInvoiceHeaders(cardDataForSKus, selectedCard);

  const handleDeleteRow = () => {
    triggerSimpleAjax(
      `${DELETE_COLLECTED_BILLS_SKU}${selectedSkuID}`, // API endpoint to delete the selected item
      "DELETE",
      {},
      () => {
        const updatedTableData = [...tableData]; // Create a copy of the table data
        updatedTableData.splice(selectedRowIndex, 1); // Remove the selected row from the copied data

        setTableData(updatedTableData); // Update the state with the new table data after deletion
        setRefreshData((prev) => !prev);
      },
      (error) => {
        console.log(error); // Handle any error if the API call fails
      }
    );
  };

  const simplifiedInvoiceSkuData =
    cardDataForSKus?.results[selectedCard]?.simplifiedinvoiceskudata || [];

  return (
    <React.Fragment>
      <Slider
        {...sliderSettings}
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
          justifyContent: "start", // Ensures the content starts from the left
        }}
      >
        {cardDataForSKus?.results.map((card, index) => (
          <Button
            key={index}
            onClick={() => handleCardClick(index)}
            border={
              savedCard !== null && index <= savedCard
                ? "1px solid green"
                : "1px solid #FFC72C"
            }
            bgColor="transparent"
            color={
              savedCard !== null && index <= savedCard ? "green" : "#FFC72C"
            }
            margin="12px 12px 0 12px"
            boxHeight="40px"
          >
            <div className="button-content">
              {savedCard !== null && index <= savedCard ? (
                <CheckCircleIcon
                  style={{ color: "green", marginRight: "8px" }}
                />
              ) : selectedCard === index ? (
                <RadioButtonCheckedIcon
                  style={{ color: "#FFC72C", marginRight: "8px" }}
                />
              ) : (
                <RadioButtonUncheckedIcon
                  style={{ color: "#FFC72C", marginRight: "8px" }}
                />
              )}
              <span className="button-text">
                {` ${card.invoice_date}, ${card.invoice_no}`}
              </span>
            </div>
          </Button>
        ))}

        <Button
          key="new-sku-data"
          onClick={() => handleCardClick("new-sku")}
          border="1px solid #FFC72C"
          bgColor="transparent"
          color="#FFC72C"
          margin="12px 12px 0 0px"
          boxHeight="40px"
        >
          <div className="button-content">
            <span className="button-text">New SKU Data</span>
          </div>
        </Button>
      </Slider>

      {selectedCard !== null && selectedCard !== "new-sku" && (
        <div className="table-container">
         <div
  className="empty-card"
  style={{
    border: "1px solid #ccc",
    padding: "10px",
    borderRadius: "8px",
  }}
>
  <div
    className="card-header"
    style={{
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: "10px",
    }}
  >
    <p
      style={{
        fontFamily: "barlow",
        fontSize: "16px",
        fontWeight: "700",
        lineHeight: "20px",
        textAlign: "left",
        margin: 0, // Remove default margin for a cleaner look
      }}
    >
      Invoice Details
    </p>
    {isExpanded ? (
      <ExpandLessIcon
        onClick={toggleExpandCollapse}
        className="toggle-icon"
        style={{ cursor: "pointer" }}
      />
    ) : (
      <ExpandMoreIcon
        onClick={toggleExpandCollapse}
        className="toggle-icon"
        style={{ cursor: "pointer" }}
      />
    )}
  </div>

  {isExpanded && (
    <div className="card-content" style={{ padding: "10px 0" }}>
      {invoiceHeaders && invoiceHeaders.length > 0 ? (
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            rowGap: "8px", // Reduces the gap between rows
            columnGap: "8px", // Reduces the gap between columns
          }}
        >
          {invoiceHeaders.map((detail, index) => (
            <div
              key={index}
              style={{
                flex: "1 0 48%", // Makes each item take up 48% of the width with 2% gap
                display: "flex", // Makes each row content aligned in the same line
                justifyContent: "space-between",
                alignItems: "center",
                padding: "5px 10px",
                backgroundColor: "#f9f9f9", // Add subtle background for each item
                borderRadius: "4px", // Rounds the corners of each item
              }}
            >
              <span
                style={{
                  fontFamily: "barlow",
                  fontSize: "13px",
                  fontWeight: "600",
                  color: "#333",
                }}
              >
                {detail.header}
              </span>
              <span
                style={{
                  fontFamily: "barlow",
                  fontSize: "13px",
                  color: "#666",
                }}
              >
                {detail.singleInput}
              </span>
            </div>
          ))}

          <div
            style={{
              flex: "1 0 100%",
              marginTop: "10px",
              display: "flex",
              alignItems: "center",
            }}
          >
            {/* You can add any additional elements here */}
          </div>
        </div>
      ) : (
        <p style={{ textAlign: "center", color: "#999" }}>
          No invoice details found.
        </p>
      )}
    </div>
  )}
</div>


          <div>
            {tableData && tableData.length > 0 ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                {/* New Button */}
                <button
                  className="total-image"
                  onClick={() => {
                    // Assuming `props.workflow_object_id` contains the value you want to pass
                    const url = `${DASHBOARDROUTES.DASHBOARD_IMAGE_VIEWER.replace(
                      ":workflow_object_id",
                      props.outletDetails.workflow_object
                    )}`;
                    window.open(url, "_blank");
                  }}
                >
                  <ImageIcon style={{ color: "#ffffff", marginRight: "8px" }} />
                  Total Images
                </button>
              </div>
            ) : null}
            <div className="table-wrapper">
              {data.length === 0 ? (
                <div className="no-data-container">
                  <span>No sku entries received in bill</span>
                </div>
              ) : (
                <div
                  className="empty-card"
                  style={{ maxWidth: "100%", overflowX: "auto" }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      marginBottom: "10px",
                       position: "sticky",
                       top: 0, 
                       zIndex: 1000,  
                       padding: "10px 0"
                    }}
                  >
                    <button
                      className="add-empty-row-button"
                      onClick={addEmptyRow}
                    >
                      <Add style={{ color: "#ffffff", marginRight: "8px" }} />
                      Add New Sku
                    </button>
                  </div>
                  <table
                    {...getTableProps()}
                    className="no-border"
                    style={{ width: "100%" }}
                  >
                    <thead>
                      {headerGroups.map((headerGroup, headerGroupIndex) => (
                        <tr
                          {...headerGroup.getHeaderGroupProps()}
                          key={`headerGroup-${headerGroupIndex}`}
                        >
                          {headerGroup.headers.map((column, columnIndex) => (
                            <th
                              {...column.getHeaderProps()}
                              key={`column-${columnIndex}`}
                              style={{
                                padding: "10px",
                                wordWrap: "break-word",
                              }}
                            >
                              {column.render("Header")}
                            </th>
                          ))}
                          <th>Action</th>{" "}
                          {/* Add Action header for the delete icon */}
                        </tr>
                      ))}
                    </thead>
                    <tbody
                      {...getTableBodyProps()}
                      style={{ fontSize: "12px" }}
                    >
                      {rows.map((row, rowIndex) => {
                        prepareRow(row);
                        return (
                          <tr
                            {...row.getRowProps()}
                            key={`row-${rowIndex}`}
                            style={{ height: "30px" }}
                          >
                            {/* SKU Name Column First */}
                            {row.cells
                              .filter((cell) => cell.column.id === "sku_name")
                              .map((cell, cellIndex) => (
                                <td
                                  {...cell.getCellProps()}
                                  key={`cell-${rowIndex}-${cellIndex}`}
                                  style={{
                                    padding: "4px 8px",
                                    verticalAlign: "middle",
                                    wordWrap: "break-word",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "inline-block",
                                      width: "auto",
                                    }}
                                  >
                                    <div>
                                      <CustomDropdown
                                        options={skuName}
                                        value={
                                          tableData[rowIndex]?.[
                                            cell.column.id
                                          ] || ""
                                        }
                                        onChange={(selectedValue) => {
                                          const updatedTableData = [
                                            ...tableData,
                                          ];
                                          updatedTableData[rowIndex][
                                            cell.column.id
                                          ] = selectedValue;
                                          setTableData(updatedTableData);
                                        }}
                                      />
                                      {tableData[rowIndex]?.[cell.column.id] &&
                                        !skuName.includes(
                                          tableData[rowIndex][cell.column.id]
                                        ) && (
                                          <div
                                            style={{
                                              color: "red",
                                              marginTop: "3px",
                                              fontSize: "11px",
                                            }}
                                          >
                                            New SKU :-{" "}
                                            {
                                              tableData[rowIndex][
                                                cell.column.id
                                              ]
                                            }
                                          </div>
                                        )}
                                    </div>
                                  </div>
                                </td>
                              ))}

                            {/* Other Columns */}
                            {row.cells
                              .filter((cell) => cell.column.id !== "sku_name")
                              .map((cell, cellIndex) => (
                                <td
                                  {...cell.getCellProps()}
                                  key={`cell-${rowIndex}-${cellIndex}`}
                                  style={{
                                    padding: "4px 8px",
                                    verticalAlign: "middle",
                                    wordWrap: "break-word",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "inline-block",
                                      width: "auto",
                                    }}
                                  >
                                    <>
                                      <span
                                        style={{
                                          visibility: "hidden",
                                          position: "absolute",
                                          padding: "0",
                                          fontFamily: "inherit",
                                          fontSize: "inherit",
                                        }}
                                        id={`span-${rowIndex}-${cell.column.id}`}
                                      >
                                        {tableData[rowIndex]?.[
                                          cell.column.id
                                        ] || ""}
                                      </span>
                                      <input
                                        type="text"
                                        value={
                                          tableData[rowIndex]?.[
                                            cell.column.id
                                          ] || ""
                                        }
                                        onChange={(event) => {
                                          handleInputChange(
                                            event,
                                            rowIndex,
                                            cell.column.id
                                          );
                                          document.getElementById(
                                            `span-${rowIndex}-${cell.column.id}`
                                          ).textContent = event.target.value;
                                        }}
                                        style={{
                                          fontSize: "12px",
                                          padding: "2px 4px",
                                        }}
                                        className="line-input"
                                        placeholder={
                                          tableData[rowIndex]?.[cell.column.id]
                                            ? ""
                                            : "Enter Text"
                                        }
                                        title={
                                          tableData[rowIndex]?.[
                                            cell.column.id
                                          ] || "Enter Text"
                                        }
                                      />
                                      <hr
                                        style={{
                                          margin: "0px 0",
                                          borderColor: "gray",
                                        }}
                                      />
                                    </>
                                  </div>
                                </td>
                              ))}

                            <td style={{ textAlign: "center" }}>
                              <DeleteIcon
                                style={{
                                  cursor: "pointer",
                                  fontSize: "20px",
                                  color: "grey",
                                }}
                                onClick={() =>
                                  handleOpenDialog(
                                    simplifiedInvoiceSkuData[rowIndex].id,
                                    rowIndex
                                  )
                                } // Pass skuID and rowIndex here
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>

                  {/* Dialog */}
                  <Dialog
                    open={openDialog}
                    onClose={handleCloseDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      {"Confirm Deletion"}
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete this row?
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={handleCloseDialog}
                        color="primary"
                        bgColor="lightgrey"
                      >
                        Cancel
                      </Button>
                      <Button onClick={confirmDelete} color="primary" autoFocus>
                        Delete
                      </Button>
                    </DialogActions>
                  </Dialog>
                </div>
              )}
            </div>
          </div>
          <button className="save-next-button" onClick={handleSaveNext}   disabled={hasNewSku} >
            Save and Next
          </button>
        </div>
      )}

      {selectedCard === "new-sku" && (
        <div>
          <p
            style={{
              fontFamily: "barlow",
              fontSize: "16px",
              fontWeight: "700",
              lineHeight: "20px",
              textAlign: "left",
            }}
          >
            {/* A
          dd on SKU's */}
          {/* <Button
                size="medium"
                color={curiousBlue300}
                bgColor={white}
                margin="0 12px 0 0"
                onClick={() => {
                    const exportAll = !(exportData || []).length
                    let q = undefined;
                    if (exportAll) {
                        const { search } = history.location
                        q = appendToSearchString(search, getManufactureListStaticQueryParams)
                    }

                    exportBulkUploadData(exportData, 'invoice_header', q).then((res) => {
                        // window.open(res.exported_file_url, "_blank");
                    });
                }}
                isDisabled={isLoading}
            >


                <ExcelIcon />
                <span
                    style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                    }}
                >
                Export to CSV
                </span>
            </Button> */}
            
          </p>
          <table className="custom-table">
      <thead>
        <tr>
        <th>New SKU's</th>
          <th>Manufacturer</th>
          <th>Brand</th>
          <th>Variant</th>
          <th>Sub Category</th>
        </tr>
      </thead>
      <tbody>
        {newAddedSKuTest.map((row, rowIndex) => (
          row.simplifiedinvoiceaddonsku.map((sku, skuIndex) => (
            <tr key={`${rowIndex}-${skuIndex}`}>
              <td>{sku.add_on_sku || "N/A"}</td>
              <td>{sku.manufacturer || "N/A"}</td>
              <td>{sku.brand || "N/A"}</td>
              <td>{sku.variant || "N/A"}</td>
              <td>{sku.sub_category || "N/A"}</td>
            </tr>
          ))
        ))}
      </tbody>
    </table>
    {selectedCard !== "new-sku"?(
      <button className="add-empty-row-button" onClick={addEmptyRow}>
            <Add style={{ color: "#ffffff", marginRight: "8px" }} />
            Add New SKU
          </button>)
          :null}
          
        </div>
      )}
    </React.Fragment>
  );
};

export default CollectedBills;
