import React, { useEffect, useRef, useState } from 'react';
import Viewer from 'viewerjs';
import 'viewerjs/dist/viewer.css';

const ImageViewer = ({ invoiceImages, skuImages, onClose }) => {
  const viewerRef = useRef(null);
  const viewerInstance = useRef(null);
  const [activeImages, setActiveImages] = useState([]); // State to hold the images to be viewed
  const [isViewerOpen, setIsViewerOpen] = useState(false); // State to control viewer visibility

  useEffect(() => {
    if (viewerRef.current && isViewerOpen && activeImages.length > 0) {
      // Initialize Viewer.js with the activeImages
      viewerInstance.current = new Viewer(viewerRef.current, {
        inline: false, // Display in modal
        viewed() {
          viewerInstance.current.zoomTo(0.3);
        },
        hidden() {
          // Only close the viewer, do not clear images
          if (onClose) onClose();
          setIsViewerOpen(false); // Hide the viewer without clearing images
        },
      });

      // Automatically open the first image in the modal
      viewerInstance.current.show();
    }

    // Cleanup the viewer instance when component unmounts or when switching images
    return () => {
      if (viewerInstance.current) {
        viewerInstance.current.destroy();
      }
    };
  }, [activeImages, isViewerOpen, onClose]);

  // Programmatically close the viewer
  const closeViewer = () => {
    if (viewerInstance.current) {
      viewerInstance.current.hide(); // Close the viewer
    }
  };

  // Function to handle opening the viewer with specific images
  const openViewer = (images) => {
    setActiveImages(images); // Set the images to view
    setIsViewerOpen(true); // Open the viewer modal
  };

  return (
    <div>
      <h3>Invoice Images</h3>
      <div>
        {invoiceImages.map((image, index) => (
          <img
            key={`invoice-${index}`}
            src={image}
            alt={`invoice-img-${index}`}
            style={{ width: 100, height: 100, cursor: 'pointer', margin: 5 }}
            onClick={() => openViewer(invoiceImages)} // Open invoice images on click
          />
        ))}
      </div>

      <h3>SKU Images</h3>
      <div>
        {skuImages.map((image, index) => (
          <img
            key={`sku-${index}`}
            src={image}
            alt={`sku-img-${index}`}
            style={{ width: 100, height: 100, cursor: 'pointer', margin: 5 }}
            onClick={() => openViewer(skuImages)} // Open SKU images on click
          />
        ))}
      </div>

      {/* Hidden image elements for viewer */}
      {isViewerOpen && (
        <div ref={viewerRef} style={{ display: 'none' }}>
          {/* Render only the Active Images */}
          {activeImages.map((image, index) => (
            <img
              key={`view-${index}`}
              src={image}
              alt={`view-img-${index}`}
            />
          ))}
        </div>
      )}
      
      {isViewerOpen && (
        <button onClick={closeViewer}>Close</button> // Button to close the viewer
      )}
    </div>
  );
};

export default ImageViewer;
