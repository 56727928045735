import React, { Component, Fragment } from "react";
import { OutletMeta } from "./OutletMeta";
import MobileFriendlyIcon from "@material-ui/icons/MobileFriendly";
import AssignmentIcon from '@material-ui/icons/Assignment';
import VerticalSplitIcon from "@material-ui/icons/VerticalSplit";
import HistoryRoundedIcon from '@material-ui/icons/HistoryRounded';
import DonutLargeIcon from '@material-ui/icons/DonutLarge';
import {
  DetailsLayoutWrapper,
  LeftSideNavs,
  RightSideContent,
} from "./outlet.styles";
import {
  GET_CALLING_TRACKER,
  GET_OUTLETS_DETAILS,
  UPLOAD_API,
} from "../../../helpers/constants/apiUrl";
import { triggerSimpleAjax } from "../../../helpers/httpHelper";
import Loader from "../../../components/Loader";
import OutletSurvey from "./outlet-survey";
import { OutletLog } from "./outlet-log";
import { QCEditHistory } from "./qc-edit-history";
import { OutletCallingScreen } from "./OutletCallingScreen";
import { connect } from "react-redux";
import { withLastLocation } from "react-router-last-location";
import { IntimationCalling } from "./IntimationCallingScreen";
import PayoutDetailsScreen from "./PayoutDetailsScreen";
import CollectedBills from "./CollectedBills";
import { InvoiceCallingScreen } from "./InvoiceCallingScreen";
import { WORKFLOW_KEYS } from "../../../helpers/constants/workflowKeys";
import { KYCUpdate } from "./KYCUpdate";
import triggerNotification from "../../../actions/triggerNotification";
import { bindActionCreators } from "redux";
import { ConfirmationCalling } from "./ConfirmationCalling";
import { VerifyPhoneNumberBlock } from "./VerifyPhoneNumberBlock";
import { ScoreDetail } from "./ScoreDetail";
import { SUB_PROJECT_TYPES } from "../../../helpers/constants/project-types";
import ScoreDetailV2 from "./ScoreDetailV2";
import LogicScoreWrapper from "./LogicScoreWrapper";
import RetailerEdit from "./RetailerEdit";
import EditIcon from "@material-ui/icons/Edit";
import ImageZoom from "../../../components/ImageZoom";
import ReactTooltip from "react-tooltip";




const getDetailsMenuName = (workflow_permission, type_of_calling) => {
  switch (workflow_permission) {
    case "calling-executive-workflow-permission": {
      if (type_of_calling === "validation") {
        return {
          menuName: "Calling Details",
          componentName: OutletCallingScreen,
        };
      } else if (type_of_calling === "intimation") {
        return {
          menuName: "Calling Details",
          componentName: IntimationCalling,
        };
      } else if (type_of_calling === "invoice") {
        return {
          menuName: "Calling Details",
          componentName: InvoiceCallingScreen,
        };
      } else if (type_of_calling === "kyc_update") {
        return {
          menuName: "Calling Details",
          componentName: KYCUpdate,
        };
      } else if (type_of_calling === "confirmation") {
        return {
          menuName: "Calling Details",
          componentName: ConfirmationCalling,
        };
      }
    }
    case "iftu-executive-workflow-permission":
    case "iftu-manager-workflow-permission":{
      return {
        menuName: "Payout Details",
        componentName: PayoutDetailsScreen,
        menuName2:"Survey Details",
        componentName2:OutletSurvey,
      };
    }

    default:
      return { menuName: "Survey Details", componentName: OutletSurvey };
      // return { menuName: "Survey Details", componentName: CollectedBills };
  }
};

class OutletDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      outletDetails: {},
      outletGroupDetails: {},
      activeGroupIndex: 0,
      activeNavIndex: 1,
      totalImages: [],
      saveClickedData: {},
      workflowObjectActionConfig: {},
      callingTrackerList: [],
      selectedCallingTrackerReasonDetails: {},
      workflowStateChanged: false
    };
  }
  getOutletDetails = () => {
    const { match, workflow_permission } = this.props;
    const { outlet } = match.params;
    triggerSimpleAjax(
      `${GET_OUTLETS_DETAILS}${outlet}/`,
      "GET",
      {},
      (response) => {
        triggerSimpleAjax(
          `${UPLOAD_API}?related_multiple_images_answer_input__workflow_object__outlet=${response.id}&related_single_image_answer_input__workflow_object__outlet=${response.id}&or_based_filter_keys=related_single_image_answer_input__workflow_object__outlet,related_multiple_images_answer_input__workflow_object__outlet&no-pagination&all_survey_images=True`,
          "GET",
          {},
          (responseImages) => {
            this.setState({ totalImages: responseImages });
          }
        );
  
        if (workflow_permission === WORKFLOW_KEYS.CALLING_EXECUTIVE && response.related_workflow_object) {
          triggerSimpleAjax(
            `${GET_CALLING_TRACKER}?linked_outlets=${(response.related_workflow_object || {}).outlet}`,
            "GET",
            {},
            (responseCallingTracker) => {
              this.setState({
                callingTrackerList: responseCallingTracker.results,
              });
            },
            (error) => {
              console.log(error);
            }
          );
        }
  
        // Check if project type is "mi"
        let updatedOutletDetails = { ...response };
        if (response.project && response.project.type === "mi"  && response.project.add_data_on_fly===true) {
          // Define staticData object
          const staticData = {
            slug: null,
            name: "Collected Bills",
            id: 3866,
            type: null,
            related_sku_products: [],
            index: null,
          };
  
          // Append staticData to question_groups if the project type is "mi"
          updatedOutletDetails = {
            ...response,
            question_groups: [...response.question_groups, staticData],
          };
        }
  
        const { sub_project } = response;
        const isScoreDetail = this.showScoreDetail(sub_project.type);
  
        // Set the updated outletDetails state with staticData appended only if the condition is met
        this.setState({
          outletDetails: updatedOutletDetails,
          activeNavIndex: isScoreDetail
            ? 0
            : workflow_permission === "iftu-manager-workflow-permission" ||
              workflow_permission === "iftu-executive-workflow-permission"
            ? 4
            : 1,
        });
      }
    );
  };
  

  // Sanjay: This was commented to prevent Dealer Board image getting displayed two times in Modal Popup
  // getTotalImages = () => {
  //   const { totalImages = [], outletDetails = {} } = this.state;
  //   let images = [];
  //   const {
  //     banner_image = null
  //   } = outletDetails;
  //   if (totalImages.length) {
  //     images = [
  //       ...totalImages,
  //     ]
  //   }
  //   if (banner_image) {
  //     images = [
  //       ...images,
  //       banner_image
  //     ]
  //   }
  //   return images
  // }

  componentDidMount() {
    const { history, match } = this.props
    const { outlet } = match.params;
    const stateData = JSON.parse(localStorage.getItem(outlet))
    if (stateData) {
      localStorage.removeItem(outlet)
      history.push(history.location.pathname, {
        ...stateData
      })
    }
    this.getOutletDetails();
  }

  componentDidUpdate(_, prevState) {
    if (prevState.activeGroupIndex !== this.state.activeGroupIndex) {
      // this.getGroupDetails(this.state.activeGroupIndex);
    }
  }

  showScoreDetail = (subProjectType) => {
    return WORKFLOW_KEYS.CLIENT_MANAGER === this.props.workflow_permission && subProjectType === SUB_PROJECT_TYPES.RETAIL_AUDIT_CYCLE
  }


  render() {
    const {
      outletDetails = {},
      outletGroupDetails,
      activeGroupIndex,
      activeNavIndex,
      totalImages,
      saveClickedData,
      workflowObjectActionConfig = {},
      selectedCallingTrackerReasonDetails = {},
      callingTrackerList = [],
      isUpdateActionListAvailable,
      workflowStateChanged
    } = this.state;
    const { workflow_permission, history, triggerNotification } = this.props;
    const { subProjectType, workflowOutletId } = history.location.state || {};
    const isCallingExecutive = WORKFLOW_KEYS.CALLING_EXECUTIVE === workflow_permission

    if (!Object.keys(outletDetails).length) {
      return <Loader />;
    }

    const {
      name,
      address,
      region,
      city,
      state,
      zip_code,
      created,
      question_groups,
      question_and_answers,
      logs = [],
      answer_action_logs=[],
      sub_project = {},
      status,
      related_workflow_object = {},
      id,
      outlet_id,
      rsid,
      related_sibling_instances = [],
      banner_image = {},
      inbound_source,
      field_survey_start_datetime,
      field_survey_end_datetime,
      field_survey_start_latitude,
      field_survey_end_latitude,
      field_survey_start_longitude,
      field_survey_end_longitude,
      notes_by_field_auditor = null,
      previous_address = null,
      distributor_gst_number = null
    } = outletDetails;


    console.log('outletDetails9999',question_groups);
    const { menuName, componentName,menuName2,componentName2 } = getDetailsMenuName(
      workflow_permission,
      (related_workflow_object || {}).type_of_calling,
    );

    

    const payoutTable =
      WORKFLOW_KEYS.IFTU_EXECUTIVE === workflow_permission &&
      (related_workflow_object || {}).type_of_calling === "confirmation";
    const isScoreDetail = this.showScoreDetail(sub_project.type)
    const isRetailEdit = related_workflow_object.interim_status
    const DetailWrapper = componentName;

    const DetailWrapper2 = componentName2;

    const openModal = window.location.search.split("=").includes("?modal")

    const {
      qc_inbound_date,
      workflow_state
    } = related_workflow_object || {};
    const staticData = {
        slug: null,
        name: "Collected Bills",
        id: 3866,
        type: null,
        related_sku_products: [],
        index: null,
      };

    return (
      <Fragment>
        <OutletMeta
          {...outletDetails}
          outletName={name}
          address={address}
          region={region}
          city={city}
          state={state}
          zip_code={zip_code}
          created={created || undefined}
          qc_inbound_date={qc_inbound_date || undefined}
          workflow_permission={workflow_permission}
          subProjectType={subProjectType}
          workflowOutletId={workflowOutletId}
          status={status}
          saveClickedData={saveClickedData}
          question_groups={question_groups}
          outlet_id={outlet_id}
          rsid={rsid}
          inbound_source={inbound_source}
          related_workflow_object={related_workflow_object}
          field_survey_start_datetime={field_survey_start_datetime}
          field_survey_end_datetime={field_survey_end_datetime}
          field_survey_start_latitude={field_survey_start_latitude}
          field_survey_end_latitude={field_survey_end_latitude}
          field_survey_start_longitude={field_survey_start_longitude}
          field_survey_end_longitude={field_survey_end_longitude}
          notes_by_field_auditor={notes_by_field_auditor}
          previous_address={previous_address}
          distributor_gst_number={distributor_gst_number}
          setSaveClickedData={(data) =>
            this.setState({ saveClickedData: data })
          }
          selectedOutletDetails={[
            {
              subProject: sub_project.type,
              workflow_state: related_workflow_object.workflow_state,
              workflowOutletId: (related_workflow_object || {}).id,
            },
          ]}
          getOutletDetails={this.getOutletDetails}
          history={history}
          setWorkflowObjectActionConfig={(data) => {
            this.setState({
              workflowObjectActionConfig: data,
            });
          }}
          callingTrackerList={callingTrackerList}
          selectedCallingTrackerReasonDetails={
            selectedCallingTrackerReasonDetails
          }
          resetCallingtrackerResonDetails={() => {
            this.setState({
              selectedCallingTrackerReasonDetails: {},
            });
          }}
          triggerNotification={triggerNotification}
          updateActionListAvailable={(value) => {
            this.setState({ isUpdateActionListAvailable: value });
          }}
          banner_image={banner_image}
          setWorkflowStateChanged={(value) => this.setState({
            workflowStateChanged: value
          })}
          workflowStateChanged={workflowStateChanged}
          isCallingExecutive={isCallingExecutive}
        />
        <DetailsLayoutWrapper>
          <LeftSideNavs>

            {isScoreDetail ? (
              <li
                className={activeNavIndex === 0 ? "active" : ""}
                onClick={() => {
                  this.setState({ activeNavIndex: 0 });
                }}
              >
                <DonutLargeIcon  data-tip data-for={`score`} />
                <ReactTooltip place="right" id={`score`}>Score</ReactTooltip>
              </li>
            ) : null}
            {menuName2 && 
            <li
              className={activeNavIndex === 4 ? "active" : ""}
              onClick={() => {
                this.setState({ activeNavIndex: 4 });
              }}
            >
              <AssignmentIcon data-tip data-for={`survey-${menuName2}`} />
              <ReactTooltip place="right" id={`survey-${menuName2}`}>{menuName2}</ReactTooltip>
            </li>}
            <li
              className={activeNavIndex === 1 ? "active" : ""}
              onClick={() => {
                this.setState({ activeNavIndex: 1 });
              }}
            >
              <MobileFriendlyIcon data-tip data-for={`survey-${menuName}`} />
              <ReactTooltip place="right" id={`survey-${menuName}`}>{menuName}</ReactTooltip>
            </li>
            
            <li
              className={activeNavIndex === 2 ? "active" : ""}
              onClick={() => {
                this.setState({ activeNavIndex: 2 });
              }}
            >
              <VerticalSplitIcon data-tip data-for={`log-details`} style={{ transform: `rotate(180deg)` }} />
              <ReactTooltip place="right" id='log-details'>
                Log Details
              </ReactTooltip>
            </li>
            <li
              className={activeNavIndex === 5 ? "active" : ""}
              onClick={() => {
                this.setState({ activeNavIndex: 5 });
              }}
            >
              <HistoryRoundedIcon data-tip data-for={`qc-edit-history`} style={{ transform: `rotate(180deg)` }} />
              <ReactTooltip place="right" id='qc-edit-history'>
                QC Edit History
              </ReactTooltip>
            </li>
            {isRetailEdit ? (
              <li
                className={activeNavIndex === 3? "active" : ""}
                onClick={() => {
                  this.setState({activeNavIndex: 3})
                }}
              >
                <EditIcon data-tip data-for={`retailers-details`} />
                <ReactTooltip place="right" id='retailers-details'>
                  Retailers Edit
                </ReactTooltip>
              </li>
            ) : ""}
          </LeftSideNavs>
          <RightSideContent
            contentMargin={payoutTable ? "24px 24px 24px -10px" : ""}
            splitColumn={isCallingExecutive}
          >
            <section>
              {activeNavIndex === 0 && !openModal ? (
                <LogicScoreWrapper
                  slug={outletDetails.slug}
                  showCurrentSurveyImageTiles={false}
                  showPrevSurveyImageTiles={true}
                  totalImageCount={totalImages.length}
                  totalImages={totalImages}
                  newTab={true}
                  outletDetails={outletDetails}
                />
              ) : null}

              {activeNavIndex === 1 || openModal ? (
                <DetailWrapper
                  question_groups={question_groups}
                  question_and_answers={question_and_answers}
                  outletDetails={outletDetails}
                  sub_project={sub_project || {}}
                  // totalImages={this.getTotalImages()}
                  totalImages={totalImages}
                  saveClickedData={saveClickedData}
                  location={this.props.location}
                  setSaveClickedData={(data) =>
                    this.setState({ saveClickedData: data })
                  }
                  workflow_state={workflow_state}
                  workflow_permission={workflow_permission}
                  triggerNotification={triggerNotification}
                  history={history}
                  related_sibling_instances={related_sibling_instances || []}
                  isUpdateActionListAvailable={isUpdateActionListAvailable}
                  workflowStateChanged={workflowStateChanged}
                />
              ) : null}


              {activeNavIndex === 2 ? <OutletLog logs={logs || []} state={this.state} /> : null}
              {activeNavIndex === 5 ? <QCEditHistory logs={answer_action_logs || []} state={this.state} /> : null}

            </section>

            {isCallingExecutive && activeNavIndex === 1 ? (
              <div style={{
                "width": "25%"
              }}>
                <VerifyPhoneNumberBlock
                  callingTrackerReasonsConfig={
                    workflowObjectActionConfig.calling_tracker_reasons_config
                  }
                  callingTrackerList={callingTrackerList}
                  selectedCallingTrackerReasonDetails={
                    selectedCallingTrackerReasonDetails
                  }
                  onReasonSelect={(trackerReason) => {
                    this.setState({
                      selectedCallingTrackerReasonDetails: {
                        ...selectedCallingTrackerReasonDetails,
                        ...trackerReason,
                      },
                    });
                  }}
                />
                <ImageZoom
                  showCurrentSurveyImageTiles={false}
                  showPrevSurveyImageTiles={true}
                  totalImageCount={totalImages.length}
                  totalImages={totalImages}
                  newTab={true}
                  outlet_slug={outletDetails.slug}
                  outlet_id={outletDetails.id}
                />
              </div>

            ) : null}

            {activeNavIndex === 3 && isRetailEdit ? (
              <RetailerEdit
                outletDetails={outletDetails}
                getOutletDetails={this.getOutletDetails}
              />
            ): null }
          </RightSideContent>
        </DetailsLayoutWrapper>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const { app, user = {} } = state;

  const { considered_workflow_permission } = user.user_details || {};

  return {
    workflow_permission: considered_workflow_permission,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    triggerNotification: bindActionCreators(triggerNotification, dispatch)
  }
}
export default withLastLocation(connect(mapStateToProps, mapDispatchToProps)(OutletDetails));
